//Reference: https://tailwindcomponents.com/component/atlassian-feature-card
// About page for app
import React from "react";
import { Footer } from "../components/Footer";
export const About = () => {
  return (
    <>
      
       <div className="h-screen flex items-center justify-center bg-gray-100">


       <p className="text-lg font-medium text-gray-700 group-hover:text-gray-700 ">  </p>



      </div>
    

    </>
  );
};
