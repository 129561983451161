import React from "react";
import { Link } from "react-router-dom";

// const navNotOpenClass = `ml-4 lg:px-8 px-4 py-4 rounded-md text-lg font-medium`;
// const navOpenClass = `block px-3 py-2 rounded-md text-base font-medium text-white`;

export const MobileNavItem = ({link, setNavOpen, currentRoute, setCurrentRoute, isNavOpen, text, isGithub}) => {

  function doThaThing(){
    setCurrentRoute(link)
    setNavOpen(false)
  }

    return (     
      <>
        {isGithub ? 
          <Link
          className={` py-1 rounded-md text-lg font-medium text-simple-gray-1e transition duration-150 ease-in hover:text-sky-500`}
          onClick={()=>window.location.replace(link)}
          >
          {text}
          </Link>
          :
          <Link
          to={link}
          className={`flex py-1 rounded-md text-lg font-medium text-simple-gray-1e transition duration-150 ease-in hover:text-sky-500`}
          onClick={()=> doThaThing()}
          >
          {text}
          </Link>
        }
      </>
    );
}