import React from "react";
import { MobileNavItems } from "./MobileNavItems";

export const MobileNav = ({isNavOpen, setNavOpen, currentRoute, setCurrentRoute}) => {
  return (
    <>
      <div className={`relative h-50 w-50 in:hidden  duration-500 transition ease-in-out delay-150`} >
        <div className={`absolute top-0 right-2 backdrop-blur-md`}>
          <MobileNavItems setNavOpen={setNavOpen} isNavOpen={isNavOpen} currentRoute={currentRoute} setCurrentRoute={setCurrentRoute}/>
        </div>
      </div>
      
    </>
  );
};
