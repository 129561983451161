// About page for app

import React from "react";
import { Link } from "react-router-dom";
export const Projects = () => {
  return (
    <>
    <div className="h-full min-h-screen flex items-center justify-center bg-gray-100">

<div className="grid grid-rows-2 xs:mt-20 xs:mb-40 w-7/12 max-w-7/12 gap-4">

  
  <div className="grid row-span-1  relative">
    <Link 
      className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" 
      onClick={()=>window.open('https://squickz.paulkerrigan.ca/', "_blank")}
    >

        <div className="flex flex-row justify-between">
        
          <div 
            className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-sky-500">
            Squickz
          </div>
          <div className=" "> 
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.921 2.081c2.771 2.772 2.771 7.269 0 10.042l-3.84 3.839-2.121-2.122 3.839-3.84c1.599-1.598 1.599-4.199-.001-5.797-1.598-1.599-4.199-1.599-5.797-.001l-3.84 3.839-2.121-2.121 3.84-3.839c2.771-2.773 7.267-2.773 10.041 0zm-8.082 13.879l-3.84 3.839c-1.598 1.6-4.199 1.599-5.799 0-1.598-1.598-1.598-4.2 0-5.797l3.84-3.84-2.121-2.121-3.84 3.84c-2.771 2.772-2.772 7.268 0 10.041 2.773 2.772 7.27 2.773 10.041 0l3.84-3.84-2.121-2.122z"/></svg>
          </div>
        
        </div>
     

      
        <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        Clicking game with login, highscores 
        </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
       School project for Web Development class
      </p>
      
    

    </Link>
  </div>

  
  <div className="grid row-span-1 relative">
    <Link 
      className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" 
      onClick={()=>window.open('https://roguestudios.io/', "_blank")}
    >

        <div className="flex flex-row justify-between">
        
          <div className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-sky-500">
            Rogue Studios
          </div>
          <div className=" "> 
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.921 2.081c2.771 2.772 2.771 7.269 0 10.042l-3.84 3.839-2.121-2.122 3.839-3.84c1.599-1.598 1.599-4.199-.001-5.797-1.598-1.599-4.199-1.599-5.797-.001l-3.84 3.839-2.121-2.121 3.84-3.839c2.771-2.773 7.267-2.773 10.041 0zm-8.082 13.879l-3.84 3.839c-1.598 1.6-4.199 1.599-5.799 0-1.598-1.598-1.598-4.2 0-5.797l3.84-3.84-2.121-2.121-3.84 3.84c-2.771 2.772-2.772 7.268 0 10.041 2.773 2.772 7.27 2.773 10.041 0l3.84-3.84-2.121-2.122z"/></svg>
          </div>
        
        </div>
     

      
        <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        Banner Builder, NFT Rarity Website for Rogue Studios 
        </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        Worked on Backend in Node.js with express and mongoose. Worked on frontend in React.js. Worked with Web3.js API, Metaplex API, Twitter API.

      </p>
      
    

    </Link>
  </div>

  <div className="grid row-span-1 relative">
    <Link 
      className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" 
      onClick={()=>window.open('https://detaild.ca/', "_blank")}
    >

        <div className="flex flex-row justify-between">
        
          <div className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-sky-500">
            Detaild
          </div>
          <div className=" "> 
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.921 2.081c2.771 2.772 2.771 7.269 0 10.042l-3.84 3.839-2.121-2.122 3.839-3.84c1.599-1.598 1.599-4.199-.001-5.797-1.598-1.599-4.199-1.599-5.797-.001l-3.84 3.839-2.121-2.121 3.84-3.839c2.771-2.773 7.267-2.773 10.041 0zm-8.082 13.879l-3.84 3.839c-1.598 1.6-4.199 1.599-5.799 0-1.598-1.598-1.598-4.2 0-5.797l3.84-3.84-2.121-2.121-3.84 3.84c-2.771 2.772-2.772 7.268 0 10.041 2.773 2.772 7.27 2.773 10.041 0l3.84-3.84-2.121-2.122z"/></svg>
          </div>
        
        </div>
     

      
        <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
         Built a booking system and Database for a local detailing company
        </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        Worked on Backend in Node.js with express and MongoDB. Worked on frontend in Next.js.

      </p>
      
    

    </Link>
  </div>

</div>



</div>
    
    </>
  );
};
