// About page for app
import { Footer } from "../components/Footer";
import React from "react";
export const WorkExperience = () => {
  return (
    <>
    <div className="h-full min-h-screen  flex items-center justify-center ">

{/* need to be able to scroll all the way
  */}
<div className="grid grid-rows-3  xs:mt-40 xs:mb-40 max-h-fit w-7/12 max-w-7/12 gap-4">

  
  <div className="grid row-span-1 relative ">
    <div className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" >

      <div className="flex flex-row justify-between gap-4">
        
      <div className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-gray-700">
        Software Developer - Scotiabank
      </div>
      <div className="text-xs text-right font-bold text-gray-700  group-hover:text-gray-700"> 
        May 2021 - Present
      </div>
      
      </div>
     
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        C#, SQL and Java Development in an Agile environment  
      </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        Worked with a team in developing and supporting a critical internal website 
      </p>

    </div>
  </div>

  
  <div className="grid row-span-1 relative">
    <div className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" >

        <div className="flex flex-row justify-between">
        
          <div className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-gray-700">
            Junior Programmer Analyst Intern - Scotiabank
          </div>
          <div className="text-xs text-right font-bold text-gray-700 group-hover:text-gray-700"> 
            May 2020 - August 2020
          </div>
        
        </div>
     

      
        <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
          Java and React.js Development 
        </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        Developed website for internal use to increase efficiency in testing 
      </p>
      
    

    </div>
  </div>
  <div className="grid row-span-1 relative">
    <div className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" >

        <div className="flex flex-row justify-between">
        
          <div className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-gray-700">
            Agile Developer Intern - Scotiabank</div>
          <div className="text-xs text-right font-bold text-gray-700 group-hover:text-gray-700"> 
          May 2019 - August 2019</div>
        
        </div>
     

      
        <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
          Java and Spring Boot Development 
        </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        API Development Modernization lab from Mainframe to Cloud
      </p>
      
    

    </div>
  </div>

  
  

</div>



</div>
    
    </>
  );
};
