import React, {useState, useLayoutEffect, useEffect} from "react";
import { NavItems } from "../components/nav/NavItems";
import { MobileNav } from "../components/nav/MobileNav";
import { MobileNavButton } from "../components/nav/MobileNavButton";

export const Nav = () => {
  const [isNavOpen, setNavOpen] = useState(false); // state for opening/closing navbar in mobile menu
  const [ route, setRoute] = useState("/");

  const setCurrentRoute = (val) =>{
    setRoute(val);
  }
  
//bg-simple-black-1
  return (
    <header className="  flex-row justify-evenly w-full z-50 fixed ">
      <div> <nav >
              <div className={`max-w-7x1 mx-auto sm:px-6 lg:px-8   `}>
                  <div className={` relative flex items-center justify-between w-full backdrop-blur-md h-20`}>
                      {/* <NavLogo/> */}
                      <div className={`xs:mx-auto ml-48 text-center sm:text-3xl lg:text-4xl text-black xs:text-2xl `}>
                        Paul Kerrigan
                      </div>
                      
                      <MobileNavButton toggleMenu={() => setNavOpen(!isNavOpen)} isNavOpen={isNavOpen} />
                      <div className={` hidden in:block sm:ml-6 items-center`}>
                      <NavItems
                        isNavOpen={isNavOpen}
                        currentRoute={route}
                        setCurrentRoute={setCurrentRoute}
                      />
                      </div>
                  </div>
                  
              </div>
              {isNavOpen ? (
                <MobileNav
                  isNavOpen={isNavOpen}
                  setNavOpen={setNavOpen}
                  currentRoute={route}
                  setCurrentRoute={setCurrentRoute}
                />
                
              ): null}
              
      </nav>
      </div>
      <div></div>
      </header>
      
      
    
      

  );

}