import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MobileNavItems } from "./MobileNavItems";


function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function MobileTest({ currentRoute, setCurrentRoute }) {
  const [isOpen, setNavOpen] = useState(false);
  const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-body dark:bg-cardBackground transition ease transform duration-300`;
 

  function closeMenu() {
    this.setState(isOpen);
  }

  var translateLength = [];
  for (let i = 0; i < 5; i++) {
    console.log(window.location.hash);
    if (window.location.hash === "#Featured") {
      var tLength = "translate-x-[106px]";
    } else if (window.location.hash === "#Search") {
      var tLength = "translate-x-[155px]";
    } else if (window.location.hash === "#Gallery") {
      var tLength = "translate-x-[205px]";
    } else if (window.location.pathname === "/rarity/form") {
      var tLength = "translate-x-[252px]";
    } else {
      var tLength = "translate-x-[60px]";
    }
    var translateLength = tLength;
  }

  return (
    <div className="w-full md:hidden relative z-50">
        
          <div className="flex gap-3 sm:gap-6 items-center relative z-50">

            <div >
              <button
                aria-controls="mobile-menu"
                className={
                  `${
                    isOpen
                    ?
                    `flex flex-col h-12 w-12 rounded-lg translate-x-16 justify-center items-center group outline outline-black dark:hover:bg-darkBorders  bg-darkBackground`
                    :`flex flex-col  h-12 w-12 rounded-lg justify-center items-center group outline outline-black dark:hover:bg-darkBorders  bg-darkBackground`
                }`}
                onClick={() => setNavOpen(!isOpen)}
              >
                <div
                  className={`${genericHamburgerLine} ${
                    isOpen
                      ? "rotate-45 translate-y-3 group-hover:opacity-100 delay-300"
                      : "group-hover:opacity-100 delay-300"
                  }`}
                ></div>

                <div
                  className={
                    isOpen
                      ? "translate-x-5 group-hover:opacity-100 z-10 delay-100 rotate-90 ease-in-out duration-300"
                      : "group-hover:opacity-100 duration-300 delay-[600ms]"
                  }
                >
                  <div
                    className={`${genericHamburgerLine} ${
                      isOpen
                        ? `"z-10 ease-in-out delay-[900ms] duration-300" `
                        : " group-hover:opacity-100 duration-300"
                    }`}
                  />
                </div>

                <div
                  className={`${genericHamburgerLine} ${
                    isOpen
                      ? "-rotate-45 -translate-y-3 group-hover:opacity-100 delay-300"
                      : "group-hover:opacity-100 delay-300"
                  }`}
                />
              </button>
            </div>
            {isOpen === true ? 
             <nav>
            <div className={`relative h-50 w-50 in:hidden duration-500 `} >
        <div className={`absolute -bottom-24 right-5 `}>
          <MobileNavItems setNavOpen={setNavOpen} currentRoute={currentRoute} setCurrentRoute={setCurrentRoute}/>
        </div>
      </div>
            </nav>
            : 
            <></>
            }
          </div>
        {/* </div> */}
    </div>
  );
}

