import React from 'react';
import './App.css';
import { Routes } from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { Nav } from './Pages/Nav'
import { Footer } from "./components/Footer";
import { PageWrapper } from "./components/PageWrapper";
import { Home } from "./Pages/Home";
import { About } from "./Pages/About";
import { Education } from "./Pages/Education";
import { WorkExperience } from "./Pages/WorkExperience";
import { Projects } from "./Pages/Projects";

function App() {
  return (
    <div className='h-full min-h-screen bg-gray-100'>
    <Router>
      <Switch>
      <PageWrapper>
        <Route exact path="/" component={Home} />
          <Nav/>
            <Route exact path="/About" component={About} />
            <Route exact path="/Experience" component={WorkExperience} />
            <Route exact path="/Education" component={Education} />
            <Route exact path="/Projects" component={Projects} />
          <Footer/>
      </PageWrapper>
      </Switch>
    </Router>
    </div>
  );
}

export default App;
