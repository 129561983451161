import React  from "react";
import { MobileNavItem } from "./MobileNavItem";

export const MobileNavItems = ({ isNavOpen, setNavOpen, currentRoute, setCurrentRoute }) => {

  return (
    <>
      <MobileNavItem
        link="/"
        text="Home"
        isGithub={false}
        isNavOpen={isNavOpen}
        setNavOpen={setNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      {/* <MobileNavItem
        link="/About"
        text="About"
        isGithub={false}
        isNavOpen={isNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      /> */}
      <MobileNavItem
        link="/Experience"
        text="Experience"
        isGithub={false}
        isNavOpen={isNavOpen}
        setNavOpen={setNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      <MobileNavItem
        link="/Education"
        text="Education"
        isGithub={false}
        isNavOpen={isNavOpen}
        setNavOpen={setNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      <MobileNavItem
        link="/Projects"
        text="Projects"
        isGithub={true}
        isNavOpen={isNavOpen}
        setNavOpen={setNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      
      
    </>
  );
};
