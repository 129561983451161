import React, {useState, useLayoutEffect} from "react";
import { NavItems } from "../components/nav/NavItems";
//import { MobileNav } from "../components/nav/MobileNav";
import { MobileTest } from "../components/nav/MobileTest";
import { MobileNavButton } from "../components/nav/MobileNavButton";

export const HomeNav = () => {
  const [isNavOpen, setNavOpen] = useState(true); // state for opening/closing navbar in mobile menu
  const [ route, setRoute] = useState("/");


  const setCurrentRoute = (val) =>{
    setRoute(val);
  }
//bg-simple-black-1
  return (
    <div className=" mb-20 justify-evenly  z-50 absolute ">
      <div> <nav className={``}>
                  <div className={` lg:px-4 relative items-center justify-between h-20`}>
                      
                      <div className={`space-x-0.5 md:mr-6 lg:mr-6 xl:mr-6 hidden md:block sm:ml-6 items-center`}>
                      <NavItems
                        isNavOpen={isNavOpen}
                        currentRoute={route}
                        setCurrentRoute={setCurrentRoute}
                      />
                      </div>
                  </div>
                  
              
                <MobileTest currentRoute={route} setCurrentRoute={setCurrentRoute}/>
              
      </nav>
      </div>
      <div></div>
      </div>
      
      
    
      

  );

}