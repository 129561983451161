import React  from "react";
import { NavItem } from "./NavItem";

export const NavItems = ({ isNavOpen, currentRoute, setCurrentRoute }) => {

  return (
    <>
      <NavItem
        link="/"
        text="Home"
        isGithub={false}
        isNavOpen={isNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      {/* <NavItem
        link="/About"
        text="About"
        isGithub={false}
        isNavOpen={isNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      /> */}
      <NavItem
        link="/Experience"
        text="Experience"
        isGithub={false}
        isNavOpen={isNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      <NavItem
        link="/Education"
        text="Education"
        isGithub={false}
        isNavOpen={isNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      <NavItem
        link="/Projects"
        text="Projects"
        isGithub={false}
        isNavOpen={isNavOpen}
        currentRoute={currentRoute}
        setCurrentRoute={setCurrentRoute}
      />
      
      
    </>
  );
};
