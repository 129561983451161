
// Home page for app
import React from "react";
import { HomeNav } from "./HomeNav";
import { Link } from "react-router-dom";
export const Home = () => {
  return (
    <>
    <main >
      <div className="h-screen flex content-center items-center justify-center bg-gray-100">
        <div className={`text-black text-6xl mb-80 xs:text-4xl xs:mx-auto `}>
          Paul Kerrigan
        </div>
        <Link 
          className={`xs:mx-auto mb-52 text-center text-2xl text-black xs:text-xl absolute hover:text-sky-500 `}
          onClick={()=>window.open('https://www.merriam-webster.com/dictionary/software%20engineering', "_blank")}
          >
          Software Engineer  
        </Link>
        <HomeNav/>
      </div>
    </main>
    </>
  );
};
