// About page for app
import { Footer } from "../components/Footer";
import React from "react";
export const Education = () => {
  return (
    <>
    <div className="h-screen flex items-center justify-center bg-gray-100">

<div className="grid grid-rows-2 w-6/12 max-w-6/12 gap-4">

  
  <div className="grid row-span-1 relative ">
    <div className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" >

      <div className="flex flex-row justify-between gap-4">
        
      <div className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-gray-700">
        Ontario Tech University
      </div>
      <div className="text-xs text-right font-bold text-gray-700  group-hover:text-gray-700"> 
      September 2019 - June 2021
      </div>
      
      </div>
     
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
      Bachelor of Science (Honours)  
      </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
      Computer Science 
      </p>

    </div>
  </div>

  
  <div className="grid row-span-1 relative">
    <div className="group shadow-lg hover:shadow-2xl duration-200 delay-75 w-full bg-white rounded-3xl py-6 pr-6 pl-9" >

        <div className="flex flex-row justify-between">
        
          <div className="md:text-2xl sm:text-lg font-bold text-gray-700 group-hover:text-gray-700">
            Durham College
          </div>
          <div className="text-xs text-right font-bold text-gray-700 group-hover:text-gray-700"> 
          January 2017 - April 2019
          </div>
        
        </div>
     

      
        <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
        Ontario College Advanced Diploma 
        </p>
      
      <p className="md:text-lg sm:text-base font-medium text-gray-700 group-hover:text-gray-700 "> 
      Computer Programmer Analyst with Honours 
      </p>
      
    

    </div>
  </div>

</div>



</div>
    
    </>
  );
};
