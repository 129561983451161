import React from "react";
import { NavClosedIcon } from "../../svgs/NavClosedIcon";
import { NavOpenIcon } from "../../svgs/NavOpenIcon";

// Appears when viewed on mobile
export const MobileNavButton = ({ toggleMenu, isNavOpen }) => {
  return (
    <>
      <div className="absolute inset-y-0 right-2 flex items-center in:hidden">
        <button
          type="button"
          className={
            "inline-flex items-center justify-center p-2 rounded-md  hover:text-white bg-darkbackground focus:outline-none ring-2 ring-inset ring-black"
          }
          aria-controls="mobile-menu"
          aria-expanded="false"
          onClick={toggleMenu}
        >
          <span className={"sr-only"}>Open Main Menu</span>
          {isNavOpen ? <NavClosedIcon /> : <NavOpenIcon />}
        </button>
      </div>
    </>
  );
};
