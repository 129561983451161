// https://github.com/benawad/dogehouse/blob/staging/kofta/src/app/components/PageWrapper.tsx
import React from "react";

export const PageWrapper = ({ children }) => {
  return (
    <div flex flex-col h-screen>  
      {children}
    </div>
  );
};
